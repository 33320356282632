import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Manrope-Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Manrope-Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Manrope-Semibold.woff\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Manrope-Bold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Manrope-ExtraBold.woff\",\"weight\":\"800\",\"style\":\"normal\"}],\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager", webpackExports: ["BackButton"] */ "/app/src/components/common/back-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Chips"] */ "/app/src/components/common/chips.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/collapsible.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/command.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/comments/comments-list.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/confirm-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LabelValueItem"] */ "/app/src/components/common/label-value-item.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/left-side-nav/left-side-nav.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/scroll-area.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SentryUserManager"] */ "/app/src/components/common/sentry-user-manager.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/sheet.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/table/collapsible-column.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/table/common-table.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/table/simple-search.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/table/table-column-settings.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/table/table-filter.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/toast/toast.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/toast/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/toast/use-toast.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ToggleFilter"] */ "/app/src/components/common/toggle-filter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToggleView"] */ "/app/src/components/common/toggle-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UploadFileArea"] */ "/app/src/components/common/upload-file-area.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/upload-file.dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/providers/next-auth.provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/providers/query-client-api.provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/providers/send-bird-com.provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/providers/send-bird-store-provider.tsx");
